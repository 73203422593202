import React from 'react';
import s from './searchresult.scss';
import { Container, Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import Button from '@material-ui/core/Button';
import saveIcon from 'assets/images/ic-save.png';
import shareIcon from 'assets/images/ic-share.png';
import detailedDataIcon from 'assets/images/ic-detailed-dataset.png';

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: any) {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
  }));
  
export default () => {
    const classes = useStyles();
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      setValue(newValue);
    };
    const theme = useTheme();
    const [value, setValue] = React.useState('one');
    const handleChangeIndex = (index: number) => {
        setValue(index);
    };
    return (

        <div className={{minHeight:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <Card className={classes.root} variant="outlined">
        <CardContent>
        <Typography component="div">
        <div className={s.resultHead__sameline}>
          <span  className={s.resultHead__h2}>Length of roads in Telangana district wise</span>
          <Typography component="span" align='right'>
          <Button href="#">
          <img src={shareIcon} alt="ShareLogo" /> Share
          </Button>
          <Button href="#">
          <img src={saveIcon} alt="SaveLogo" /> Save
          </Button>
          </Typography>
        </div>
        </Typography>
        <div>
          <Typography component="div" className={s.sourceText}>
            <span className={s.sourceText}>Source: <span className={s.sourceText__link}>R&B Department Government Of Telangana</span>
            <Button href="#">
              <img src={detailedDataIcon} />
            </Button> 
            <span className={s.sourceText__history}> <span className={s.sourceText__bullet}>&#8226;</span>Last updated Apr 13, 2020 </span></span>
          </Typography>
        </div>
          <Typography component="div">
          <span className={s.descriptionText}>
            In Telangana State, the road assets of R&B Dept. comprises of 24,245 kms of road length with 3,152 kms of State Highways, 12,079 kms of Major District Roads, and 9,014 kms of Other District Roads. 16 National Highways are passing through the State of Telangana covering a length of 2,592 kms, out of which 868 kms are with National Highways Authority of India (NHAI).
            </span>
            </Typography>
            <Typography component="div">
            <span className={s.detaileddata}>
            Click here for: <span className={s.sourceText__link}> Detailed Dataset</span>
            <Button href="#">
                <img src={detailedDataIcon} />
              </Button> 
              </span>
            </Typography>
                <div className={classes.root}>
                    <AppBar position="static">
                        <Tabs value={value} onChange={handleChange} aria-label="wrapped label tabs example">
                            <Tab
                                value="one"
                                label="Visualization"
                                wrapped
                                {...a11yProps('one')}
                            />
                            <Tab value="two" label="Data Preview" {...a11yProps('two')} />
                            <Tab value="three" label="Metadata" {...a11yProps('three')} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index="one">
                        Item One
                        <div>
                            {/* <CanvasJSChart options = {options} /> */}
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index="two">
                        Item Two
                    </TabPanel>
                    <TabPanel value={value} index="three">
                        Item Three
                    </TabPanel>
                </div>
        </CardContent>
    </Card>
    <br/>
    <Card className={classes.root} variant="outlined">
        <CardContent>
        <Typography component="div">
          Relevant Indicators
          <Typography className={s.indicators} component="span">
          View all
          </Typography>
        </Typography>
        </CardContent>
        <hr />
        <CardContent>
        Roads length vs Registered motor vehicles
        <br />
        <span className={s.sourceText}>
        <span className={s.sourceText__link}>R&B Department Government Of Telangana</span>
        <span className={s.sourceText__history}> 
        <span className={s.sourceText__bullet}>&#8226;</span>
        </span>
        Last updated Apr 13, 2020 </span>
        </CardContent>
        <CardContent>
        Classification Roads
        <br />
        <span className={s.sourceText}>
        <span className={s.sourceText__link}>R&B Department Government Of Telangana</span>
        <span className={s.sourceText__history}> 
        <span className={s.sourceText__bullet}>&#8226;</span>
        </span>
        Last updated Apr 13, 2020 </span>
        </CardContent>
        <CardContent>
        Length of Roads in Major District
        <br />
        <span className={s.sourceText}>
        <span className={s.sourceText__link}>R&B Department Government Of Telangana</span>
        <span className={s.sourceText__history}> 
        <span className={s.sourceText__bullet}>&#8226;</span>
        </span>
        Last updated Apr 13, 2020 </span>
        </CardContent>
    </Card>
        </div>
    )
}
